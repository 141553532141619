import http from "./http";

const formaPagamentoMensal = {
  fetchTiposPagamento: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "GET",
        url: `formapagamentomensal/tipospagamentos`,
        params: payload,
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  save: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "POST",
        url: `formapagamentomensal`,
        data: payload,
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  fetchParcelasPagamento: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "GET",
        url: `formapagamentomensal/parcelas`,
        params: payload,
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  fetchParcelasPagar: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "GET",
        url: `formapagamentomensal/contaspagar`,
        params: payload,
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  fetchParcelasAtrasadas: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "GET",
        url: `formapagamentomensal/contasatrasadas`,
        params: payload,
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  fetchParcelas7Dias: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "GET",
        url: `formapagamentomensal/contas7dias`,
        params: payload,
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  pagarParcela: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "PUT",
        url: `formapagamentomensal/pagarparcela`,
        data: payload,
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  fetchParcelasPorFonecedor: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "GET",
        url: `formapagamentomensal/contasfornecedor`,
        params: payload,
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  delete: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "DELETE",
        url: `formapagamentomensal`,
        data: { ...payload }
      })
        .then((resp) => { resolve(resp.data) })
        .catch((error) => { reject(error.response.data) })

    })
  }
}

export default formaPagamentoMensal;
