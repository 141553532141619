<template>
  <v-card class="pt-5">
    <v-data-table
      :headers="headers"
      :items="contas"
      :loading="loading"
      :options.sync="options"
      :server-items-length="pageable.totalElements"
      hide-default-footer
      dense
    >
      <template v-slot:[`item.data`]="{ item }">
        {{ item.dataPagamento | localDateToString }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-switch
          v-model="item.pago"
          color="success darken-2"
          @change="pagar(item)"
          small
          hide-details
          style="margin: 5px"
        >
        </v-switch>
      </template>
      <template v-slot:[`item.valor`]="{ item }">
        {{ item.valor | formataMoeda }}
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="pageable.page"
        :length="pageable.totalPages"
        @input="changePage"
      ></v-pagination>
    </div>
  </v-card>
</template>

<script>
import FormaPagmento from "@/service/formaPagamentoMensal";
export default {
  name: "ContasPagarAtrasadas",
  props: {
    projetoId: {
      type: Number,
      default: 0,
      required: false,
    },
  },
  data() {
    return {
      loading: false,
      contas: [],
      headers: [
        {
          text: "Projeto",
          value: "nomeProjeto",
          align: "start",
          filterable: false,
          sortable: false,
        },
        {
          text: "Cliente",
          value: "nomeCliente",
          filterable: false,
          sortable: false,
        },
        {
          text: "Fornecedor",
          value: "nomeFornecedor",
          filterabldatae: false,
          sortable: false,
        },
        {
          text: "Forma pagamento",
          value: "tipoPagamento",
          filterabldatae: false,
          sortable: false,
        },
        {
          text: "Data",
          value: "data",
          filterabldatae: false,
          sortable: false,
        },
        {
          text: "Valor",
          value: "valor",
          filterabldatae: false,
          sortable: false,
        },
        {
          text: "Pago",
          value: "actions",
          filterabldatae: false,
          sortable: false,
        },
      ],
      options: {},
      pageable: {},
    };
  },

  methods: {
    changePage(value) {
      this.pageable.page = value;
      this.buscar({ page: value - 1 });
    },
    async buscar(options) {
      this.loading = true;
      options = { ...options, projetoId: this.projetoId };

      try {
        const resp = await FormaPagmento.fetchParcelasAtrasadas({ ...options });
        this.contas = resp.content;
        this.pageable = {
          totalElements: resp.totalElements,
          totalPages: resp.totalPages,
          pageSize: resp.size,
          page: resp.number + 1,
        };
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
      this.loading = false;
    },
    async pagar(item) {
      if (!item.parcelaId) return;

      const payload = {
        id: item.parcelaId,
        pago: item.pago,
      };
      try {
        await FormaPagmento.pagarParcela({ ...payload });

        if (item.pago) {
          this.$notify({
            title: "Sucesso",
            text: "Parcela paga!",
            type: "success",
          });
        }
      } catch (error) {
        this.$notify({
          title: "Erro",
          text: "Desculpe, ocorreu um erro no sistema!",
          type: "error",
        });
        item.pago = false;
      }
    },
  },
  mounted() {
    this.buscar();
  },
};
</script>

<style>
</style>