<template>
  <v-card>
    <v-card-title>Contas a Pagar</v-card-title>
    <v-card-text>
      <v-tabs>
        <v-tab @click="currentComponent = 'Atrasadas'">Contas Atrasadas</v-tab>
        <v-tab @click="currentComponent = 'Proximos'">Próximos 7 dias</v-tab>
        <v-tab @click="currentComponent = 'Periodo'">Por período</v-tab>
      </v-tabs>
      <keep-alive>
        <component v-bind:is="currentComponent"></component>
      </keep-alive>
    </v-card-text>
  </v-card>
</template>

<script>
import Atrasadas from "./Atrasadas";
import Proximos from "./Proximos";
import Periodo from "./Periodo";
export default {
  components: {
    Atrasadas,
    Proximos,
    Periodo,
  },
  data() {
    return {
      currentComponent: "Atrasadas",
    };
  },
};
</script>